tr.deactive{
    background-color: #ffd9dd;
}

.pointer{
    cursor: pointer;
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}

.addDropdown {
    // .dropdown-menu {
    //     left: initial !important;
    //     right: 0 !important;
    //     top: 110% !important;
    //     transform: none !important;
    // }
}

.nowrap {
    white-space: nowrap;
}

.phoneInput {
    display: flex;
    justify-content: space-between;

    >*:first-child {
        width: 60px;
    }

    & *:last-child {
        width: calc(100% - 70px);
    }
}

.modal-dialog-md {
    max-width: 900px !important;
}




// \\\\\\\\\\\\\\\### Tabs ##\\\\\\\\\\\\\\

.btn_new.active {
    background: none !important;
    box-shadow: none !important;
    color: #6db562 !important;
    border-bottom: 2px solid #6db562;
    border-radius: 0px !important;
}
.btn_new{
    width: fit-content !important;
    color: #344054 !important;
   
}
.layout_tab {
    border: 1px solid #EAECF0;
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    margin-top: 1.5rem;
}
.layout_tab h3 {
    font-size: 14px;
    font-weight: 600;
    color: #344054;
    margin-bottom: 0px;
}
.layout_tab p {
    font-size: 14px;
    color: #6B7280;
    font-weight: 500;
}
.check_inline {
    border: 1px solid #EBEBEB;
    padding: 4px 8px;
    border-radius: 6px;
}
.input_icons input {
    padding: 0px 40px;
}
.input_icons .email {
    position: absolute;
    top: 9px;
    left: 12px;
    font-size: 20px;
    color: #667085;
}
.input_icons .check {
    position: absolute;
    top: 9px;
    right: 12px;
    font-size: 20px;
    color: #6db562;
}
h1.add_heading {
    font-size: 20px;
    font-weight: 700;
    color: #111827;
}
.input_icons{
    position: relative;
}
p.list_pform {
    border: 1px solid #ebebeb;
    padding: 10px;
    border-radius: 8px;
}
.ul_new {
    border-bottom: 1px solid #ebebeb;
}
span.color_no {
    color: #6db562;
}
.input_control{
    display: flex;
    align-items: center;
}
.input_control i {
    background: #FDE9EA;
    width: 35px;
    height: 35px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffcaca;
    margin-left: 10px;
    cursor: pointer;
}
.add_input {
    color: #6db562 !important;
    margin-top: 12px;
    cursor: pointer;
}

.drag_input {
    border: 1px solid #dcdcdc;
    min-height: 155px;
    border-radius: 12px;
}
.upload_imginput p {
    margin-bottom: 0px;
    font-size: 14px;
}
.upload_imginput {
    text-align: center;
}
.upload_imginput span {
    color: #6db562;
}
.drag_input {
    position: relative;
}
.drag_input input {
    min-height: 155px;
    border-radius: 12px;
    position: absolute;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.upload_imginput {
    text-align: center;
    margin-top: 2rem;
}
.upload_imginput .material-icons {
    color: #6db562;
    box-shadow: 0px 0px 10px -6px #000;
    padding: 6px;
    border-radius: 50%;
    margin-bottom: 12px;
}
.check_checklist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.check_checklist .form-check {
    padding-right: 10px;
}
.row_lins img {
    width: 34px;
    height: 34px;
    border-radius: 4px;
}
.row_lins {
    display: flex;
    width: 100%;
    column-gap: 10px;
    align-items: center;
}
.save_div {
    background: #FAFBFC;
    padding: 2rem;
}
hr.hr_class {
    width: 97% !important;
}

// \\\\\\\\\\\\\\\### Tabs end ###\\\\\\\\\\\\\\

// \\\\\\\\\\\\\\\### View page ###\\\\\\\\\\\\\\
.Sup_div img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
}
.Sup_div h1 {
    font-size: 16px;
    color: #3C3E49;
    margin-top: 10px;
    font-weight: 600;
}
.Sup_div p {
    font-size: 14px;
    color: #6B7280;
}
.heading_rk {
    font-size: 20px;
    color: #3C3E49;
    margin: 2rem 0px;
}
.heading_rk2 {
    font-size: 28px;
    color: #3C3E49;
   margin-bottom: 1rem;
}
.div_backgeound {
    background: #F0F0F2;
    padding: 20px;
}
.div_backgeound h1 {
    font-size: 14px;
    font-weight: 600;
    min-width: 135px;
    color: #344054;
    margin-bottom: 0px;
}
.div_backgeound p {
    color: #475467;
    font-size: 14px;
    margin-bottom: 0px;
}
.div_backgeound {
    display: flex;
    border-radius: 12px;
}
.row_layouts h1 {
    font-size: 14px;
    color: #344054;
    font-weight: 600;
    margin-bottom: 0px;
}
.row_layouts p {
    color: #475467;
    font-size: 14px;
    margin-bottom: 0px;
}
.row_layouts {
    padding: 20px 0px;
    border-bottom: 1px solid #ebebeb;
}
p.btn_p {
    width: fit-content;
    background: #705ee626;
    color: #705EE6;
    padding: 2px 8px;
    border-radius: 4px;
}
.data_h1 a {
    text-decoration: underline;
    color: #1D2433;
}
.trems_row h1 {
    font-size: 14px;
    color: #1D2433;
    font-weight: 600;
}
    .trems_row p {
        font-size: 14px;
        color: #4B5563;
        margin-bottom: 0px;
    }
    .trems_row {
        margin: 2rem 0px;
    }
    .table_heading {
        margin: 1rem 0px;
        font-size: 14px;
        font-weight: 600;
        color: #344054; 
    }
    .table_div h1 {
        font-size: 14px;
        color: #101828;
        background: none;
        margin-bottom: 0px;
        padding: 0px;
    }
    .table_div p {
        font-size: 14px;
        font-weight: 400;
        color: #475467;
        margin-bottom: 0px;
        padding: 0px;
        background: none;
    }
    .table_div th {
        background: #EAECF0;
        text-align: center;
    }
    .table_div td {
        color: #475467;
        text-align: center;
    }
    td:first-child {
       text-align: left;
      }
      th:first-child {
        text-align: left;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    th:last-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
       .p_heading {
        font-size: 14px;
        color: #75757A;
    }
    .con_heading{
        font-size: 24px;
        font-weight: 600;
        color: #3C3E49;
    }
    .form_headingtop {
        background: #101828;
        padding: 20px;
        color: #fff;
        margin-bottom: 0px;
        font-size: 16px;
        display: flex;
        font-weight: 600;
        border-radius: 12px 12px 0px 0px;
        position: relative;
}
    .form_headingtop ul li {
        font-weight: normal;
        color: #fff;
    }
    .form_headingtop ul{
        margin-bottom: 0px;
    }
    .main_view {
        padding: 20px;
        background: #fff;
    }
    .form_headingtop .material-icons {
        position: absolute;
        right: 15px;
        top: 18px;
        cursor: pointer;
    }
    td.td_back {
        background: #EAECF0;
        border: 0px !important;
    }
    
    hr { 
        border-top: 1px solid #767676 !important;
    }
    .nav-pills .nav-link {
        border-radius: 50px;
        text-align: center;
        width: 120px;
          display: block;
          margin: auto;
      }

// \\\\\\\\\\\\\\\### View page end ###\\\\\\\\\\\\\\