.sendclss {
    color: #fff !important;
}

form.p-5.rounded.shadow {
    height: 100%;
}




.loginimg {
    width: 100%;
}

form.p-3.rounded.shadow {
    border-top: none;
}

img.loginimg.w-100 {
    height: 100%;
    object-fit: cover;
}

form.centerLogin {
    width: 418px;
    display: block;
    margin: auto;
}

img.logimg {
    width: 100%;
    max-width: 167px;
    max-height: 100px;
    object-fit: cover;
}


.login-wrapper {
    .mainfromclss {
        max-width: 900px;
    }
}

img.logo {
    width: 300px;
    height: auto;

}

.logo_img {
    padding: 0rem 3rem;
}

.banner_img {
    background-image: url(../../../public/assets/img/login_Img.png);
    height: 100vh;
    width: 100%;
    // background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0rem;
}

.loginLeftImg h3 {
    position: absolute;
    bottom: 10px;
    font-size: 45px;
    font-weight: 600;
    line-height: 61px;
    color: #fff;
    padding: 4rem 2rem;
}

.right_side {
    height: 100vh;
    display: flex;
    position: relative;
    justify-content: center;
}

p.accopunt {
    text-align: right;
    right: 55px;
    padding: 4rem 1rem;
    font-size: 16px;
    font-weight: 400;
}

p.accopuntt {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    color: #84818A;
    word-spacing: 3px;
}

a.sign_up {
    text-decoration: none;
}

h3.text-left.lgtext {
    font-weight: 600;
    font-size: 24px;
}

.bginput {
    border-radius: 8px !important;
    border: 1px solid #E8E8E8;
    height: 50px;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #6db562 !important;
    text-decoration: none;
}

.text-primary {
    color: #6db562 !important;
    font-size: 16px;
}

.forget {
    text-align: left;
    margin: 1rem 0px;
}

.title-around-span {
    position: relative;
    text-align: center;
    color: #484848;
    font-size: 16px;

    &:before {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        background-color: #E8E8E8;
    }

    span {
        position: relative;
        z-index: 1;
        padding: 0 15px;
        background: #ffff;
    }
}


.google_logo {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

img.gogle_img {
    width: 30px;
    height: 30px;
    margin: 0px 5px;
}

img.gogle_imgs {
    width: 25px;
    height: 24px;
    margin: 0px 5px;
}

.google_id {
    border: 1px solid #E8E8E8;
    width: 173px;
    height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

a.google_heading {
    font-size: 16px;
    font-weight: 600;
    color: #202020;

}

.google_id {
    width: 178px;
    border: 1px solid #E8E8E8;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

p.para_forget {
    font-size: 14px;
    font-weight: 400;
    color: #484848;
}